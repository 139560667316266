import React from "react";
import Hero from "../Hero";
import AboutSection from "../Section/AboutSection";
import BrandsSection from "../Section/BrandsSection";
import Banner from "../Section/BannerSection";
import Section from "../Section";
import FeaturesSection from "../Section/FeaturesSection";
import TestimonialSection from "../Section/TestimonialSection";
import BlogSection from "../Section/BlogSection";
import AppointmentSection from "../Section/AppointmentSection";
import FaqSection from "../Section/FaqSection";
import AwardSection from "../Section/AwardSection";
import DepartmentSection from "../Section/DepartmentSection";
import { pageTitle } from "../../helpers/PageTitle";
import RequestDemoSection from "../RequestDemo/RequestDemoSection";
import PricingSection from "../Section/PricingSection";
import { Icon } from "@iconify/react/dist/iconify.js";
const pricingData = [
  {
    title: "Cabinet",
    subTitle:
      "Special small family cabinet. Benefit from tailored system settings and support for your golden years.",
    price: "$25",
    period: "/month",
    color: "#7BC9FF",
    featureList: [
      "Single Database",
      "Single Domain",
      "limited Support",
      "Data Migration",
    ],
    btnText: "Get Started",
    btnUrl: "/contact",
    popular: false,
  },
  {
    title: "Small Clinic",
    subTitle:
      "Extensive, medium-sized clinic tailored to your needs with a few employees. Experience unlimited functionalities.",
    price: "$50",
    period: "/month",
    color: "#387ADF",
    featureList: [
      "Single Database",
      "Single Domain",
      "limited Support",
      "Data Migration",
    ],
    btnText: "Get Started",
    btnUrl: "/contact",
    popular: false,
  },
  {
    title: "Medium Clinic",
    subTitle:
      "Extensive, for your medium clinic with medium employees. Experience unlimited functionalities",
    price: "$75",
    period: "/month",
    color: "#1D24CA",
    featureList: [
      "Single Database",
      "Single Domain",
      "Unlimited Support",
      "Data Migration",
    ],
    btnText: "Get Started",
    btnUrl: "/contact",
    popular: false,
  },
  {
    title: "Health Care Authority",
    subTitle:
      "Extensive, for your Hospital with many employees. Experience unlimited functionalities",
    price: "15",
    period: "/month",
    color: "#0C359E",
    featureList: [
      "Unlimited Disk Space",
      "Unlimited Support",
      "Data Migration",
    ],
    btnText: "Get Started",
    btnUrl: "/contact",
    popular: false,
  },
  {
    title: "Hospital",
    subTitle:
      "Optimized for government healthcare Institutions and NGOs. Get access to administration and assessment",
    price: "250",
    period: "/month",
    color: "#0C359E",
    featureList: [
      "Unlimited Disk Space",
      "Unlimited Support",
      "Data Migration",
    ],
    btnText: "Get Started",
    btnUrl: "/contact",
    popular: false,
  },
  // {
  //   title: `Women's Health Plan`,
  //   subTitle: `Comprehensive women's health services. Receive expert gynecological care, family planning support, and prenatal/postnatal.`,
  //   price: "$169",
  //   period: "/month",
  //   featureList: [
  //     `Women's Health Services`,
  //     "Gynecological Care",
  //     "Fall Prevention Programs",
  //     "Family Planning",
  //     "Prenatal & Postnatal Support",
  //   ],
  //   btnText: "Get Started",
  //   btnUrl: "/contact",
  //   popular: false,
  // },
  // {
  //   title: "Sports & Fitness Plan",
  //   subTitle:
  //     "Optimized for athletes. Access sports injury assessments, physiotherapy, and expert advice for peak performance and recovery.",
  //   price: "$119",
  //   period: "/month",
  //   featureList: [
  //     "Sports Injury Assessments",
  //     "Physiotherapy Sessions",
  //     "Sports Medicine Experts",
  //     "Fitness Support",
  //   ],
  //   btnText: "Get Started",
  //   btnUrl: "/contact",
  //   popular: false,
  // },
];
const featureListData = [
  {
    iconSrc: "/images/home_1/compassion.svg",
    title: "Patient Registration and Management",
    subTitle: [
      {
        sub: "Secure storage of patient demographics, medical history, insurance information, and contact details.",
      },
      {
        sub: "Ability to easily register new patients and update existing patient records.",
      },
      {
        sub: "Integration with identification verification systems for accurate patient identification.",
      },
    ],
  },
  {
    iconSrc: "/images/home_1/excellence.svg",
    title: "Appointment Scheduling and Calendar Management",
    subTitle: [
      {
        sub: "Online appointment booking for patients with real-time availability and confirmation.",
      },
      {
        sub: "Appointment calendar for healthcare providers to manage their schedules and view patient appointments.",
      },
      {
        sub: "Automated reminders of upcoming appointments via Telegram or mobile patient notifications.",
      },
    ],
  },
  {
    iconSrc: "/images/home_1/integrity.svg",
    title: "Electronic Health Records (EHR)",
    subTitle: [
      {
        sub: "Digital capture, storage, and retrieval of patient health records, including medical history, diagnoses, medications, and treatment plans.",
      },
      {
        sub: "Customizable templates for documenting clinical encounters, progress notes, and lab results",
      },
      {
        sub: "Secure access controls to protect patient confidentiality and comply with healthcare regulations.",
      },
    ],
  },
  {
    iconSrc: "/images/home_1/teamwork.svg",
    title: "Billing and Invoicing",
    subTitle: [
      {
        sub: "Generation of invoices, statements, and billing codes for services rendered.Integration with insurance billing systems for claims submission and reimbursement.",
      },
      {
        sub: "Tracking of payment status, outstanding balances, and revenue reporting.",
      },
    ],
  },
  {
    iconSrc: "/images/home_1/teamwork.svg",
    title: "Prescription Management",
    subTitle: [
      {
        sub: "Electronic prescribing of medications with integration with pharmacies for automatic refills and dispensing.",
      },
      {
        sub: "Drug interaction checking and allergy alerts to ensure patient safety.",
      },
      {
        sub: "Tracking of prescription history and medication adherence.",
      },
    ],
  },
  {
    iconSrc: "/images/home_1/teamwork.svg",
    title: "Clinical Decision Support",
    subTitle: [
      {
        sub: "Integration with clinical guidelines and evidence-based practices to support healthcare providers in making informed treatment decisions.",
      },
      {
        sub: "Alerts and reminders for preventive screenings, vaccinations, and follow-up care.",
      },
      {
        sub: "Decision support tools for diagnosing and managing common medical conditions.",
      },
    ],
  },
  {
    iconSrc: "/images/home_1/teamwork.svg",
    title: "Patient Portal and Communication Tools",
    subTitle: [
      {
        sub: "Secure patient portal for accessing health records, lab results, and educational resources. ",
      },
      {
        sub: "Two-way communication between patients and healthcare providers for asking questions, requesting prescription refills, and scheduling appointments.",
      },
      {
        sub: " Educational materials and self-management tools for patients to take an active role in their healthcare.",
      },
    ],
  },
  {
    iconSrc: "/images/home_1/teamwork.svg",
    title: "Reporting and Analytics",
    subTitle: [
      {
        sub: "Generation of reports on clinic performance, patient demographics, appointment utilization, and financial metrics.",
      },
      {
        sub: "Analysis of key performance indicators (KPIs) to identify trends, areas for improvement, and opportunities for growth.",
      },
      {
        sub: "Customizable dashboards for administrators and stakeholders to monitor clinic operations in real-time.",
      },
    ],
  },
  {
    iconSrc: "/images/home_1/teamwork.svg",
    title: "Integration with External Systems",
    subTitle: [
      {
        sub: "Seamless integration with laboratory systems, diagnostic imaging, pharmacies, and other healthcare providers for interoperability and care coordination. ",
      },
      {
        sub: " Integration with telehealth platforms for virtual consultations and remote monitoring of patients.",
      },
      {
        sub: " Compatibility with health information exchange (HIE) networks for sharing patient data securely across healthcare organizations.",
      },
    ],
  },
];
const brandData = [
  { imgUrl: "images/brand_1.png", imgAlt: "Brand" },
  { imgUrl: "images/brand_2.png", imgAlt: "Brand" },
  { imgUrl: "images/brand_3.png", imgAlt: "Brand" },
  { imgUrl: "images/brand_4.png", imgAlt: "Brand" },
  { imgUrl: "images/brand_5.png", imgAlt: "Brand" },
  { imgUrl: "images/brand_6.png", imgAlt: "Brand" },
  { imgUrl: "images/brand_7.png", imgAlt: "Brand" },
  { imgUrl: "images/brand_8.png", imgAlt: "Brand" },
];
const faqData = [
  {
    title: "What services does Vibolrith’s Health System offer?",
    content:
      "At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesent voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui.",
  },
  {
    title: "How do I schedule an appointment with Vibolrith’s Health System?",
    content:
      "At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesent voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui.",
  },
  {
    title: "Do you accept insurance?",
    content:
      "At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesent voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui.",
  },
  {
    title: "What should I bring to my appointment?",
    content:
      "At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesent voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui.",
  },
  {
    title: "How do I request a prescription refill?",
    content:
      "At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesent voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui.",
  },
];
const blogData = [
  {
    title: "BASIC",
    monthly: "$25",
    advance: [
      { subTitle: "10 GB Disk Space" },
      { subTitle: "Single Database" },
      { subTitle: "Single Domain" },
    ],
    bgColor: [{ color: "#8E8FFA" }, { color: "green" }, { color: "#af43f1" }],
  },
  {
    title: "STANDARD",
    monthly: "$50",
    advance: [
      { subTitle: "20 GB Disk Space" },
      { subTitle: "Single Database" },
      { subTitle: "Single Domain" },
    ],
    bgColor: [{ color: "#f953c6" }, { color: "#8045c6" }, { color: "#af43f1" }],
  },
  {
    title: "PREMUIM",
    monthly: "$100",
    advance: [
      { subTitle: "40 GB Disk Space" },
      { subTitle: "Single Database" },
      { subTitle: "Single Domain" },
    ],
    bgColor: [{ color: "#6dd5ed" }, { color: "#8045c6" }, { color: "#af43f1" }],
  },
  {
    title: "SPECIAL",
    monthly: "",
    advance: [{ subTitle: "Negotiate" }, { subTitle: "Unlimited Disk Space" }],
    bgColor: [{ color: "#f64f59" }, { color: "#8045c6" }, { color: "#af43f1" }],
  },
];
const awardData = [
  {
    title: "Efficient Patient Management",
    subTitle:
      "Clinic management systems streamline patient registration, appointment scheduling, and record-keeping processes, reducing administrative burdens and improving workflow efficiency.",
    iconUrl: "images/icons/award.svg",
  },
  {
    title: "Enhanced Patient Experience",
    subTitle:
      "By offering online appointment booking, reminders, and secure patient portals, clinic management systems empower patients to take a more active role in their healthcare, leading to higher levels of patient satisfaction and engagement.",
    iconUrl: "images/icons/award.svg",
  },
  {
    title: "Improved Clinical Workflow",
    subTitle:
      "Electronic health records (EHR) and clinical decision support tools within clinic management systems enable healthcare providers to access patient information quickly, make informed treatment decisions, and deliver high-quality care more efficiently.",
    iconUrl: "images/icons/award.svg",
  },
  {
    title: "Optimized Resource Utilization",
    subTitle:
      "Clinic management systems help healthcare organizations optimize resource allocation by providing insights into appointment utilization, staff productivity, and facility usage, leading to better resource management and cost savings.",
    iconUrl: "images/icons/award.svg",
  },
  {
    title: "Streamlined Billing and Revenue Cycle Management",
    subTitle:
      "Automated billing and invoicing features in clinic management systems streamline the billing process, reduce billing errors, and accelerate revenue cycle management, ultimately improving financial performance",
    iconUrl: "images/icons/award.svg",
  },
  {
    title: "Compliance and Data Security",
    subTitle:
      "Clinic management systems are designed to comply with healthcare regulations, such as HIPAA, ensuring that patient data is protected, and privacy is maintained. Robust security measures safeguard against data breaches and unauthorized access.",
    iconUrl: "images/icons/award.svg",
  },
  {
    title: "Integration and Interoperability",
    subTitle:
      "Many clinic management systems offer integration with external systems, such as laboratory information systems, diagnostic imaging systems, and health information exchanges, facilitating seamless data exchange and interoperability across healthcare settings",
    iconUrl: "images/icons/award.svg",
  },
  {
    title: "Data-driven Decision Making",
    subTitle:
      "Clinic management systems provide valuable insights and analytics through reporting tools, enabling healthcare organizations to make data-driven decisions, identify trends, and monitor key performance indicators to continuously improve operations and patient outcomes.      ",
    iconUrl: "images/icons/award.svg",
  },
  {
    title: "Scalability and Flexibility",
    subTitle:
      "Clinic management systems are scalable and adaptable to the needs of healthcare organizations of all sizes, from small clinics to large healthcare systems. They can accommodate growth, changes in workflow, and evolving regulatory requirements.",
    iconUrl: "images/icons/award.svg",
  },
  {
    title: "Remote Access and Telehealth Support",
    subTitle:
      "With the rise of telehealth, many clinic management systems offer remote access and telehealth support features, allowing healthcare providers to conduct virtual consultations, provide remote care, and engage with patients outside of traditional clinic settings.",
    iconUrl: "images/icons/award.svg",
  },
];
const departmentData = [
  {
    title: "Emergency Department",
    iconUrl: "images/home_1/department_icon_1.svg",
    href: "/departments/department-details",
  },
  {
    title: "Pediatric Departement",
    iconUrl: "images/home_1/department_icon_2.svg",
    href: "/departments/department-details",
  },
  {
    title: "Gynecology Department",
    iconUrl: "images/home_1/department_icon_3.svg",
    href: "/departments/department-details",
  },
  {
    title: "Cardiology Department",
    iconUrl: "images/home_1/department_icon_4.svg",
    href: "/departments/department-details",
  },
  {
    title: "Neurology Department",
    iconUrl: "images/home_1/department_icon_5.svg",
    href: "/departments/department-details",
  },
  {
    title: "Psychiatry Department",
    iconUrl: "images/home_1/department_icon_6.svg",
    href: "/departments/department-details",
  },
];

export default function Home() {
  pageTitle("Home");
  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Use smooth scrolling behavior if supported
    });
  };
  return (
    <>
      <Hero
        title="Vibolrith’s Health System"
        subTitle="<strong>Vibolrith's health system </strong> is a health system management. <br/>

The system refers to the strategic planning, organization, coordination and monitoring of all activities within a healthcare system or organization to ensure efficient and effective delivery of healthcare services. It encompasses a broad range of responsibilities aimed at optimizing healthcare organization performance, improving patient outcomes and maximizing resource utilization."
        bgUrl="/images/home_1/hero_bg.jpeg"
        imgUrl="/images/home_1/img_home.png"
        videoBtnText="See how we work"
        videoUrl="/video/VHS_Clinic_App_1.mp4"
        infoList={[
          {
            title: "Phone",
            subTitle: "+855 12750 523",
            iconUrl: "/images/contact/icon_1.svg",
          },
          {
            title: "Email",
            subTitle: "info.vibolrith-health-system.com",
            iconUrl: "/images/icons/ambulance.svg",
          },
          {
            title: "Location",
            subTitle: "35Z, Street 600, Sangkat Beoung Kak2, Khan Tuol Kork",
            iconUrl: "/images/icons/pin.svg",
            onClick: () =>
              window.open("https://maps.app.goo.gl/XqWbq3FFkwrkjHFZ6", "blank"),
          },
        ]}
        btnText="Book Now"
        btnUrl="/requestDemo"
      />
      {/* Start Feature Section */}
      <Section
        topMd={10}
        topLg={10}
        topXl={50}
        bottomMd={185}
        bottomLg={140}
        bottomXl={100}
      >
        <FeaturesSection sectionTitle="Our Values" data={featureListData} />
      </Section>
      {/* End Feature Section */}
      {/* Start About Section */}
      <Section>
        <AboutSection
          imgUrl="/images/home_1/img_home3.png"
          spiningImgUrl="/images/home_1/about_mini.svg"
          title="About Us"
          subTitle=""
          featureList={[
            {
              featureListTitle:
                "Vibolrith's Health System is a Cambodia-based healthcare software company with the vision of providing world-class healthcare software products at affordable prices.",

              featureListSubTitle:
                "Clinic Systems is practice management software designed to manage all aspects of clinic operations. This customizable clinical information system is an integrated healthcare solution that includes OPD management, pharmacy, laboratory, radiology, mobile application, online appointment scheduling, secured messaging, doctor portal, patient and family portals, medical electronic billing, accounting, etc .",
            },
          ]}
        />
      </Section>

      {/* <Section topMd={10} topLg={10} topXl={50}>
        <AwardSection
          sectionTitle="Vibolrith’s Health System is Intuitive and efficiently to use"
          data={awardData}
        />
      </Section> */}

      {/* <Section topMd={20} topLg={20} topXl={50}>
        <PricingSection
          sectionTitle="Our pricing & Plans"
          data={pricingData}
          is_small={false}
        />
      </Section> */}

      <span class="cs_scrollup cs_scrollup_show" onClick={handleScrollToTop}>
        <Icon icon="eva:arrow-upward-fill" />
      </span>
      {/* End About Section */}
      {/* Start Departments Section */}
      {/* <Section topMd={185} topLg={150} topXl={110}>
        <DepartmentSection
          sectionTitle="Departments"
          bgUrl="images/home_1/department_bg.svg"
          data={departmentData}
        />
      </Section> */}

      {/* End Departments Section */}
      {/* Start Award Section */}
      {/* End Blog Section */}
      {/* Start Appointment Section */}
      {/* <Section topMd={190} topLg={145} topXl={105} id="appointment">
        <RequestDemoSection
          sectionTitle="Request Demo"
          sectionTitleUp=""
          imgUrl="/images/home_1/img_home4.png"
        />
      </Section> */}
      {/* End Appointment Section */}
      {/* Start FAQ Section */}
      {/* <Section topMd={190} topLg={145} topXl={105}>
        <FaqSection
          data={faqData}
          sectionTitle="Usually Asked"
          sectionTitleUp="What People"
        />
      </Section> */}
      {/* End FAQ Section */}
      {/* Start Brand Section */}
      {/* <Section
        topMd={200}
        topLg={150}
        topXl={110}
        bottomMd={200}
        bottomLg={150}
        bottomXl={110}
      >
        <BrandsSection data={brandData} />
      </Section> */}
      {/* End Brand Section */}
      {/* End Award Section */}
      {/* Start Testimonial */}
      {/* <Section
        topMd={185}
        topLg={140}
        topXl={100}
        bottomMd={200}
        bottomLg={150}
        bottomXl={110}
      >
        <TestimonialSection
          sectionTitle="Some Reviews"
          sectionTitleDown="Of our clients"
        />
      </Section> */}
      {/* End Testimonial */}
      {/* Start Banner Section */}
      {/* <Section>
        <Banner
          bgUrl="images/home_1/cta_bg.svg"
          imgUrl="images/home_1/home8.jpg"
          title="Don’t Let Your Health Take a Backseat!"
          subTitle="Schedule an appointment with one of our experienced medical professionals today!"
        />
      </Section> */}
      {/* End Banner Section */}
      {/* Start Blog Section */}
    </>
  );
}
