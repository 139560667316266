import { Icon } from "@iconify/react";
import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export default function AppointmentForm() {
  const [selectedDate, setSelectedDate] = useState(null);
  return (
    <form action="#" className="row">
      <div className="col-lg-6">
        <label className="cs_input_label cs_heading_color">First Name</label>
        <input type="text" className="cs_form_field" placeholder="" />
        <div className="cs_height_42 cs_height_xl_25" />
      </div>
      <div className="col-lg-6">
        <label className="cs_input_label cs_heading_color">Last Name</label>
        <input type="text" className="cs_form_field" placeholder="" />
        <div className="cs_height_42 cs_height_xl_25" />
      </div>
      <div className="col-lg-12">
        <label className="cs_input_label cs_heading_color">Phone Number</label>
        <input type="text" className="cs_form_field" placeholder="" />
        <div className="cs_height_42 cs_height_xl_25" />
      </div>
      <div className="col-lg-12">
        <label className="cs_input_label cs_heading_color">Your Address</label>
        <textarea className="cs_form_field" placeholder="" rows={5} />
        <div className="cs_height_42 cs_height_xl_25" />
      </div>
      <div className="col-lg-12">
        <label className="cs_input_label cs_heading_color">
          Organization type
        </label>
        <div className="cs_radio_group">
          <div className="cs_radio_wrap  col-lg-3">
            <input
              className="cs_radio_input"
              type="radio"
              name="organization"
              id="Small Cabinet"
              defaultValue="Small Cabinet"
            />
            <label className="cs_radio_label" htmlFor="Small Cabinet">
              Small Cabinet
            </label>
          </div>
          <div className="cs_radio_wrap  col-lg-3">
            <input
              className="cs_radio_input"
              type="radio"
              name="organization"
              id="Public Clinic"
              defaultValue="Public Clinic"
            />
            <label className="cs_radio_label" htmlFor="Public Clinic">
              Public Clinic
            </label>
          </div>
          <div className="cs_radio_wrap  col-lg-3">
            <input
              className="cs_radio_input"
              type="radio"
              name="organization"
              id="Private Clinic"
              defaultValue="Private Clinic"
            />
            <label className="cs_radio_label" htmlFor="Private Clinic">
              Private Clinic
            </label>
          </div>
          <div className="cs_radio_wrap col-lg-3 ">
            <input
              className="cs_radio_input"
              type="radio"
              name="organization"
              id="Government Healthcare Authority"
              defaultValue="Government Healthcare Authority"
            />
            <label
              className="cs_radio_label"
              htmlFor="Government Healthcare Authority"
            >
              Government Healthcare Authority
            </label>
          </div>
          <div className="cs_radio_wrap  col-lg-3">
            <input
              className="cs_radio_input"
              type="radio"
              name="organization"
              id="Private Hospital"
              defaultValue="Private Hospital"
            />
            <label className="cs_radio_label" htmlFor="Private Hospital">
              Private Hospital
            </label>
          </div>
          <div className="cs_radio_wrap  col-lg-3">
            <input
              className="cs_radio_input"
              type="radio"
              name="organization"
              id="Public Hospital"
              defaultValue="Public Hospital"
            />
            <label className="cs_radio_label" htmlFor="Public Hospital">
              Public Hospital
            </label>
          </div>
        </div>

        <div className="cs_height_42 cs_height_xl_25" />
      </div>
      {/* ==== */}
      <div className="col-lg-12">
        <label className="cs_input_label cs_heading_color">
          Choose your area of expertise
        </label>

        <div className="cs_radio_group">
          <div className="cs_radio_wrap col-lg-3">
            <input
              className="cs_radio_input"
              type="checkbox"
              name="expertise"
              id="General Consulting"
              defaultValue="General Consulting"
            />
            <label className="cs_radio_label" htmlFor="General Consulting">
              General Consulting
            </label>
          </div>
          <div className="cs_radio_wrap col-lg-3">
            <input
              className="cs_radio_input"
              type="checkbox"
              name="expertise"
              id="Pediatric"
              defaultValue="Pediatric"
            />
            <label className="cs_radio_label" htmlFor="Pediatric">
              Pediatric
            </label>
          </div>
          <div className="cs_radio_wrap col-lg-3">
            <input
              className="cs_radio_input"
              type="checkbox"
              name="expertise"
              id="Gyneco/Obstetric"
              defaultValue="Gyneco/Obstetric"
            />
            <label className="cs_radio_label" htmlFor="Gyneco/Obstetric">
              Gyneco/Obstetric
            </label>
          </div>
          <div className="cs_radio_wrap  col-lg-3">
            <input
              className="cs_radio_input"
              type="checkbox"
              name="expertise"
              id="Anesthesia"
              defaultValue="Anesthesia"
            />
            <label className="cs_radio_label" htmlFor="Anesthesia">
              Anesthesia
            </label>
          </div>
          <div className="cs_radio_wrap  col-lg-3">
            <input
              className="cs_radio_input"
              type="checkbox"
              name="expertise"
              id="Emergency"
              defaultValue="Emergency"
            />
            <label className="cs_radio_label" htmlFor="Emergency">
              Emergency
            </label>
          </div>

          <div className="cs_radio_wrap  col-lg-3">
            <input
              className="cs_radio_input"
              type="checkbox"
              name="expertise"
              id="Internal Medicine"
              defaultValue="Internal Medicine"
            />
            <label className="cs_radio_label" htmlFor="Internal Medicine">
              Internal Medicine
            </label>
          </div>

          <div className="cs_radio_wrap  col-lg-3">
            <input
              className="cs_radio_input"
              type="checkbox"
              name="expertise"
              id="Psychiatry"
              defaultValue="Psychiatry"
            />
            <label className="cs_radio_label" htmlFor="Psychiatry">
              Psychiatry
            </label>
          </div>
          <div className="cs_radio_wrap  col-lg-3">
            <input
              className="cs_radio_input"
              type="checkbox"
              name="expertise"
              id="Dermatology"
              defaultValue="Dermatology"
            />
            <label className="cs_radio_label" htmlFor="Dermatology">
              Dermatology
            </label>
          </div>
          <div className="cs_radio_wrap  col-lg-3">
            <input
              className="cs_radio_input"
              type="checkbox"
              name="expertise"
              id="Orthopedics"
              defaultValue="Orthopedics"
            />
            <label className="cs_radio_label" htmlFor="Orthopedics">
              Orthopedics
            </label>
          </div>
          <div className="cs_radio_wrap  col-lg-3">
            <input
              className="cs_radio_input"
              type="checkbox"
              name="expertise"
              id="Oncology"
              defaultValue="Oncology"
            />
            <label className="cs_radio_label" htmlFor="Oncology">
              Oncology
            </label>
          </div>
          <div className="cs_radio_wrap  col-lg-3">
            <input
              className="cs_radio_input"
              type="checkbox"
              name="expertise"
              id="Diabetes"
              defaultValue="Diabetes"
            />
            <label className="cs_radio_label" htmlFor="Diabetes">
              Diabetes
            </label>
          </div>
        </div>

        <div className="cs_height_42 cs_height_xl_25" />
      </div>
      {/* === */}

      <div className="col-lg-12">
        <button className="cs_btn cs_style_1">
          <span>Submit</span>
          <i>
            <img src="/images/icons/arrow_white.svg" alt="Icon" />
            <img src="/images/icons/arrow_white.svg" alt="Icon" />
          </i>
        </button>
      </div>
    </form>
  );
}
