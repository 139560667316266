import React from "react";
import RequestDemo from ".";
import SectionHeading from "../SectionHeading";
import Spacing from "../Spacing";

const contactInfo = [
  { title: "Phone", subTitle: "+855 12750 523" },
  { title: "Email Us", subTitle: "hellocallcenter@gmail.com" },
  {
    title: "Our Location",
    subTitle:
      "35Z, Street 600, Sangkat Beoung Kak2, Khan Tuol Kork, Phnom Penh, Cambodia",
  },
];
export default function RequestDemoForm() {
  return (
    <>
      <div className="container">
        {/* <div className="row">
          <div className="col-lg-7">
            <h2 className="cs_fs_40 cs_medium mb-0">Request Demo</h2>
            <div className="cs_height_42 cs_height_xl_25" />
            <RequestDemo />
          </div>
          <div className="col-lg-4 offset-lg-1">
            <div className="cs_height_lg_100" />
            <h2 className="cs_fs_40 cs_medium mb-0">Contact Info</h2>
            <div className="cs_height_60 cs_height_xl_40" />
            <img
              src="/images/home_1/img_home7.jpeg"
              alt="Appointment"
              className="cs_radius_25 w-100"
            />
            <div className="cs_height_100 cs_height_xl_60" />
            <ul className="cs_contact_info cs_style_1 cs_mp0">
              {contactInfo.map((item, index) => (
                <li key={index}>
                  <h3 className="cs_fs_24 cs_semibold mb-0">{item.title}</h3>
                  <p className="mb-0 cs_heading_color">{item.subTitle}</p>
                </li>
              ))}
            </ul>
          </div>
        </div> */}
        <div className="container">
          <div className="row align-items-center ">
            <SectionHeading title={"Request Demo"} titleUp={""} />
            <div className="col-lg-7">
              <Spacing md="72" lg="50" />
              <RequestDemo />
              {/* for request deom */}
            </div>
            <div className="col-lg-4 offset-lg-1 text-center">
              <img
                src={"images/home_1/img_home5.jpeg"}
                alt="Appointment"
                className="cs_radius_30"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
