import React, { useState } from "react";
import Button from "../Button";
import { Icon } from "@iconify/react";
import { Link as RouterLink, useNavigate } from "react-router-dom";

export default function Pricing({
  title,
  subTitle,
  price,
  period,
  featureList,
  btnText,
  btnUrl,
  popular,
  color,
  is_small,
  setCheckIndex,
  index,
  checkIndex,
}) {
  const [hovered, setHovered] = useState(false);
  const [object, setObject] = useState(null);

  console.log("index", object);

  return (
    <>
      <div className="cs_pricing_card cs_style_1 cs_radius_20 overflow-hidden">
        <div
          className="cs_pricing_card_head cs_accent_bg cs_white_color"
          style={{ height: "60%" }}
        >
          <h3 className="cs_white_color cs_fs_24 cs_semibold">
            <span
              className="cs_accent_bg cs_fs_20"
              // style={{ background: color }}
            >
              {title}
            </span>
            {popular && (
              <span className="cs_heading_color cs_normal">Popular</span>
            )}
          </h3>
          {subTitle && <p>{subTitle}</p>}
          {price && (
            <h4 className="cs_white_color mb-0 cs_fs_40 cs_semibold mb-5">
              {price}
              <span className="cs_fs_24">{period}</span>
            </h4>
          )}
        </div>
        <div className="cs_pricing_card_body">
          <div style={{ height: "160px" }}>
            <ul className="cs_pricing_card_feature cs_fs_20 cs_heading_color">
              {featureList?.map((item, index) => (
                <li key={index}>
                  <i>
                    <Icon icon="fa6-solid:circle-check" />
                  </i>
                  {item}
                </li>
              ))}
            </ul>
          </div>
          {!is_small && (
            <div className="cs_pricing_card_btn">
              <Button btnUrl={btnUrl} btnText={btnText} variant="w-100" />
            </div>
          )}
        </div>
      </div>
      {/* */}
    </>
  );
}
