import React from "react";
import { Link } from "react-router-dom";
import "../../sass/component/Privacy.scss";

export default function Privacy() {
  return (
    <div className="container">
      <div className="container-privacy">
        <span className="container-title">
          Vibolrith's Health System is committed to safeguarding your privacy.
          <Link to="/">Contact us</Link> if you have any questions or problems
          regarding the use of your Personal Data and we will gladly assist you.{" "}
          <br />
          By using this site you consent to the Processing of your Personal Data
          as described in this Privacy Policy.
        </span>
        <h3 className="mt-5 mb-2">Table of Contents</h3>
        <ol class="custom-numbered-list">
          <li>
            <a href="#definitions">Definitions used in this Policy</a>
          </li>
          <li>
            <a href="#principles">Data protection principles we follow</a>
          </li>
          <li>
            <a href="#rights">
              What rights do you have regarding your Personal Data
            </a>
          </li>
          <li>
            <a href="#personal-data">What Personal Data we gather about you</a>
          </li>
          <li>
            <a href="#how-we-use-your-personal-data">
              How we use your Personal Data
            </a>
          </li>
          <li>
            <a href="#who-else-has-access">
              Who else has access to your Personal Data
            </a>
          </li>
          <li>
            <a href="#how-can-we-secure">How we secure your data</a>
          </li>
          <li>
            <a href="#children-policy">Children’s policy</a>
          </li>
          <li>
            <a href="#info-cookie">Information about cookies</a>
          </li>
          <li>
            <a href="#contact-info">Contact information</a>
          </li>
        </ol>
        {/* -------------------------------------------------------------------------- */}
        <div id="definitions">
          <h2 className="cs_fs_40 ol-title">Definitions</h2>
          <span className="container-title">
            <span style={{ color: "#428aca" }}>Personal Data</span> – any
            information relating to an identified or identifiable natural
            person. <br />
            <span style={{ color: "#428aca" }}>Processing</span> – any operation
            or set of operations which is performed on Personal Data or on sets
            of Personal Data. <br />
            <span style={{ color: "#428aca" }}>Data subject</span> – a natural
            person whose Personal Data is being Processed. <br />
            <span style={{ color: "#428aca" }}>Child</span> – a natural person
            under the age of 16 years. <br />
            <span style={{ color: "#428aca" }}>We/us</span> (either capitalized
            or not) – Vibolrith's Health System located at # 35Z, Street 600,
            Sangkat Beoung Kak2, Khan Tuol Kork, Phnom Penh, Cambodia.
          </span>
          <br />
          <br />
        </div>
        {/* --------------- */}

        <div id="principles">
          <h2 className="cs_fs_40 ol-title">Data Protection Principles</h2>
          <span className="container-title">
            We promise to follow the following data protection principles:
          </span>
          <ul className="ul-styled">
            <li>
              Processing is lawful, fair and transparent. Our Processing
              activities have lawful grounds. We always consider your rights
              before Processing Personal Data. We will provide you information
              regarding Processing upon request.
            </li>
            <li>
              Processing is limited to the purpose. Our Processing activities
              fit the purpose for which Personal Data was gathered.
            </li>
            <li>
              Processing is done with minimal data. We only gather and Process
              the minimal amount of Personal Data required for any purpose.
            </li>
            <li>
              Processing is limited within a time period. We will not store your
              personal data for longer than needed.
            </li>
            <li>We will do our best to ensure the accuracy of data.</li>
            <li>
              We will do our best to ensure the integrity and confidentiality of
              data
            </li>
          </ul>
        </div>
        {/* --------------- */}
        <div id="rights">
          <h2 className="cs_fs_40 ol-title">Data Subject’s (your) rights</h2>
          <span className="container-title">
            You as the Data Subject have the following rights:
          </span>
          <ol class="custom-numbered-list-definition">
            <li>
              Right to information – meaning you have the right to know whether
              your Personal Data is being processed; what data is gathered, from
              where it is obtained and why and by whom it is processed.
            </li>
            <li>
              Right to access – meaning you have the right to access the data
              collected from/about you. This includes your right to request and
              obtain a copy of your Personal Data gathered.*
            </li>
            <li>
              Right to rectification – meaning you have the right to request
              rectification or erasure of your Personal Data that is inaccurate
              or incomplete.*
            </li>
            <li>
              Right to erasure – meaning in certain circumstances you can
              request for your Personal Data to be erased from our records.
            </li>
            <li>
              Right to restrict processing – meaning where certain conditions
              apply, you have the right to restrict the Processing of your
              Personal Data.
            </li>
            <li>
              Right to object to processing – meaning in certain cases you have
              the right to object to Processing of your Personal Data, for
              example in the case of direct marketing.
            </li>
            <li>
              Right to object to automated Processing – meaning you have the
              right to object to automated Processing, including profiling; and
              not to be subject to a decision based solely on automated
              Processing. This right you can exercise whenever there is an
              outcome of the profiling that produces legal effects concerning or
              significantly affecting you.
            </li>
            <li>
              Right to data portability – you have the right to obtain your
              Personal Data in a machine-readable format or if it is feasible,
              as a direct transfer from one Processor to another.*
            </li>
            <li>
              Right to lodge a complaint – in the event that we refuse your
              request under the Rights of Access, we will provide you with a
              reason as to why. If you are not satisfied with the way your
              request has been handled please contact us.
            </li>
            <li>
              Right for the help of supervisory authority – meaning you have the
              right for the help of a supervisory authority and the right for
              other legal remedies such as claiming damages.*
            </li>
            <li>
              Right to withdraw consent – you have the right to withdraw any
              given consent for Processing of your Personal Data.
            </li>
          </ol>
        </div>
        {/* --------------- */}
        <div id="personal-data">
          <h2 className="cs_fs_40 ol-title">
            Data we gather about you Information you provided us
          </h2>
          <h5>Information you provided us</h5>
          <span className="container-title">
            This is your name and e-mail address – mainly information that is
            necessary for making an initial contact with us to consider a
            service we could offer you in marketing, advertising and other
            digital services. This information is provided to us in an email via
            our online form and this email will be used to reply to your initial
            inquiry. We do not store information from our web form on a
            database, unless we enter into a binding agreement, in which you
            will have further details of personal data use.
            <br /> <br />
          </span>
          <h5>Information automatically collected about you</h5>
          <span className="container-title">
            This includes information that is automatically stored by cookies
            and other session tools.
            <br />
            For the purpose of clarity, such information is in the form of IP
            addresses, unique session IDs, device ID’s and does NOT include your
            name, email address or any information that directly identifies you.
            <br />
            For example, your IP address is stored on our server logs and used
            by security and statistical type software to record the pages
            visited, the type of browser and device used. This information is
            used to improve our web site and to monitor the security of the
            site. <br />
            <br />
          </span>
        </div>
        {/* --------------- */}

        <div id="how-we-use-your-personal-data">
          <h2 className="cs_fs_40 ol-title">How we use your Personal Data</h2>
          <span className="container-title">
            We use your Personal Data that you supplied to us on our Demo
            Request form, to reply to your message and initiate communications
            about the services we could offer you.
            <br /> <br />
            We use your Personal Data on legitimate grounds for the following
            purposes:
            <br />
          </span>
          <ul className="ul-styled">
            <li>To prepare a demonstration of our software products.</li>
            <li>
              To prepare a customized proposal for our software products and
              services.
            </li>
            <li>
              To analyze user behavior on our web site in order to improve
              usability, content and design.
            </li>
            <li>
              To analyze user behavior in order to improve the quality, variety
              and availability of the products and services we offer.
            </li>
            <li>
              To monitor the security of our website. As long as you have not
              informed us otherwise, we consider offering you our products and
              services that are similar or the same to your purchasing history
              to be our legitimate interest.
            </li>
          </ul>
          <span className="container-title">
            We may also Process your data in order to fulfill a legal
            obligation.
            <br />
            <br />
          </span>
        </div>
        {/* --------------- */}
        <div id="who-else-has-access">
          <h2 className="cs_fs_40 ol-title">
            Who else can access your Personal Data
          </h2>
          <span className="container-title">
            We do not sell your Personal Data to third parties, period.
            <br />
            <br />
            We may share your Personal Data to trusted parties in order to make
            providing products and services to you possible. For example, we may
            share your data with system integrators, consultants or service
            companies in information technology, or other trusted partners, in
            order to receive proposals for the ultimate purpose of entering into
            a contract for providing you our software solutions.
            <br />
            <br />
            We only work with trusted parties who are able to ensure an adequate
            level of protection of your Personal Data.
            <br />
            <br />
            When we are legally obliged to do so, we may also disclose your
            Personal Data to third parties or public officials.
            <br />
            <br />
          </span>
        </div>
        {/* --------------- */}
        <div id="how-can-we-secure">
          <h2 className="cs_fs_40 ol-title">How we secure your data</h2>
          <span className="container-title">
            We do our best to keep your Personal Data safe. We use safe
            protocols for communication and transferring data (such as HTTPS)
            from our web site and with our email exchanges. We use anonymizing
            and pseudo-anonymizing where suitable. We monitor our systems for
            possible vulnerabilities and make necessary updates when possible,
            and we use firewalls that monitor incoming and outgoing traffic from
            our web site to avoid cyber-attacks.
            <br />
            <br />
            Even though we try our best we cannot guarantee the security of
            information. However, we promise to notify suitable authorities of
            data breaches. We will also notify you if there is a threat to your
            rights or interests. We will do everything we reasonably can to
            prevent security breaches and to assist authorities should any
            breach occur.
            <br />
            <br />
          </span>
        </div>
        {/* --------------- */}

        <div id="children-policy">
          <h2 className="cs_fs_40 ol-title">Our Children’s Policy</h2>
          <span className="container-title">
            We do not intend to collect or knowingly collect information from
            children. We do not target children for our software products and
            services.
            <br />
            <br />
          </span>
        </div>
        {/* --------------- */}
        <div id="info-cookie">
          <h2 className="cs_fs_40 ol-title">
            Cookies and other technologies we use
          </h2>
          <span className="container-title">
            We use cookies and/or similar technologies to apply settings for
            your web site preferences as well as analyze user behavior across
            our web site. This is done in order to enhance the content of our
            web site and improve the visitor experience. For more information
            please scroll to the top and click on the “Cookie usage” tab.
            <br />
            <br />
          </span>
        </div>
        {/* --------------- */}
        {/* <div id="contact-info">
          <h2 className="cs_fs_40 ol-title">Cookie Usage</h2>
          <p>Content for Rights section...</p>
        </div> */}
        {/* --------------- */}
      </div>
    </div>
  );
}
