import React, { useState } from "react";
import { Link } from "react-router-dom";
import "../../sass/component/Privacy.scss";
import Privacy from "./Privacy";
import Cookie from "./Cookie";
import BannerSectionStyle3 from "../Section/BannerSection/BannerSectionStyle3";
import Section from "../Section";

export default function PrivacyPage() {
  const [activeTab, setActiveTab] = useState("privacy");

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };
  return (
    <>
      {/* <BannerSectionStyle3
        bgUrl="/images/appointments/banner_bg.svg"
        imgUrl="/images/home_1/img_home6.png"
        title="Let Registration with our health system"
        subTitle=""
      /> */}
      <Section
        topMd={200}
        topLg={150}
        topXl={110}
        bottomMd={200}
        bottomLg={150}
        bottomXl={110}
      >
        <div className="tabs-container">
          <div className="tabs">
            <button
              className={activeTab === "privacy" ? "active" : ""}
              onClick={() => handleTabClick("privacy")}
            >
              Privacy Policy
            </button>
            <button
              className={activeTab === "cookie" ? "active" : ""}
              onClick={() => handleTabClick("cookie")}
            >
              Cookie Usage
            </button>
          </div>
          <div className="tab-content">
            {activeTab === "privacy" && <Privacy />}
            {activeTab === "cookie" && <Cookie />}
          </div>
        </div>
      </Section>
    </>
  );
}
