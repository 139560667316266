import React from "react";
import ContactInfoWidget from "../Widget/ContactInfoWidget";
import MenuWidget from "../Widget/MenuWidget";
import SocialWidget from "../Widget/SocialWidget";
import Newsletter from "../Widget/Newsletter";
import TextWidget from "../Widget/TextWidget";
const menuDataOne = [
  { title: "About Us", href: "/about", target: false },
  // { title: "Departments", href: "/departments" },
  // { title: "Doctors", href: "/doctors" },
  // { title: "Timetable", href: "/timetable" },
  // { title: "Appointment", href: "/appointments" },
  // { title: "Testimonials", href: "/" },
  { title: "Contact Us", href: "/contact", target: false },
  // { title: "FAQs", href: "/", target: false },
  { title: "Privacy Policy", href: "/privacy-policy", target: true },
  { title: "Terms and Conditions", href: "/", target: false },
];
const menuDataTwo = [
  { title: "Blog", href: "/blog" },
  { title: "Contact Us", href: "/contact" },
  // { title: "FAQs", href: "/" },
  { title: "Privacy Policy", href: "/" },
  { title: "Terms and Conditions", href: "/" },
];

export default function Footer() {
  return (
    <footer className="cs_footer cs_style_1 cs_heading_color">
      <div
        className="cs_footer_logo_wrap"
        style={{ backgroundImage: "url(/images/footer_bg_1.svg)" }}
      >
        <div
          className="cs_footer_brand"
          style={{ backgroundImage: "url(/images/footer_logo_bg.svg)" }}
        >
          <img
            src="/images/logo/image 21.png"
            alt="Logo Icon"
            className="cs_footer_brand_icon"
            style={{ width: "auto", height: "15%" }}
          />
          {/* <h2 className="cs_footer_brand_text">VSH</h2> */}
        </div>
      </div>
      <div className="cs_footer_main">
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <div className="cs_footer_item">
                <TextWidget text="Vibolrith's Health System" />
                <ContactInfoWidget />
              </div>
            </div>
            <div className="col-lg-4 ">
              <div className="cs_footer_item">
                <MenuWidget data={menuDataOne} />
              </div>
            </div>
            {/* <div className="col-lg-2">
              <div className="cs_footer_item">
                <MenuWidget data={menuDataTwo} />
              </div>
            </div> */}
            <div className="col-lg-4">
              <div className="cs_footer_item">
                <Newsletter
                  title="Be Our Members"
                  subTitle="To get the latest news about health from our experts"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="cs_footer_bottom cs_accent_bg">
        <div className="container">
          <div className="cs_footer_bottom_in">
            <div className="cs_copyright">
              Copyright © 2024 Vibolrith's Health System. All rights reserved.
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
