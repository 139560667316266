import React from "react";
import BannerSectionStyle3 from "../Section/BannerSection/BannerSectionStyle3";
import Section from "../Section";
import ChooseUsPage from ".";

export default function ChooseUsSection() {
  return (
    <>
      <BannerSectionStyle3
        bgUrl="/images/appointments/banner_bg.svg"
        imgUrl="/images/home_1/img_home2.png"
        title="Why Choose Vibolrith's Clinic System"
        subTitle=""
      />
      <Section
        topMd={10}
        topLg={10}
        topXl={50}
        bottomMd={200}
        bottomLg={150}
        bottomXl={110}
      >
        <ChooseUsPage />
      </Section>
    </>
  );
}
