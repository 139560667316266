import React, { useEffect, useState } from "react";
import "../../sass/component/Contact.scss";
import Section from "../Section";
import PricingSection from "../Section/PricingSection";
const pricingData = [
  {
    title: "Cabinet",
    subTitle:
      "Special small family cabinet. Benefit from tailored system settings and support for your golden years.",
    price: "$25",
    period: "/month",
    color: "#7BC9FF",
    featureList: [
      "Single Database",
      "Single Domain",
      "Limited Support",
      "Data Migration",
    ],
    btnText: "Get Started",
    btnUrl: "/contact",
    popular: false,
  },
  {
    title: "Small Clinic",
    subTitle:
      "Extensive, medium-sized clinic tailored to your needs with a few employees. Experience unlimited functionalities.",
    price: "$50",
    period: "/month",
    color: "#387ADF",
    featureList: [
      "Single Database",
      "Single Domain",
      "Unlimited Support",
      "Data Migration",
    ],
    btnText: "Get Started",
    btnUrl: "/contact",
    popular: false,
  },
  {
    title: "Medium Clinic",
    subTitle:
      "Extensive, for your medium clinic with medium employees. Experience unlimited functionalities",
    price: "$75",
    period: "/month",
    color: "#1D24CA",
    featureList: [
      "Single Database",
      "Single Domain",
      "Unlimited Support",
      "Data Migration",
    ],
    btnText: "Get Started",
    btnUrl: "/contact",
    popular: false,
  },
  {
    title: "Health Care Authority",
    subTitle:
      "Optimized for government healthcare Institutions and NGOs. Get access to administration and assessment",
    price: "$15",
    period: "/month",
    color: "#0C359E",
    featureList: [
      "Unlimited Disk Space",
      "Unlimited Support",
      "Data Migration",
    ],
    btnText: "Get Started",
    btnUrl: "/contact",
    popular: false,
  },
  {
    title: "Hospital",
    subTitle:
      "Extensive, for your Hospital with many employees. Experience unlimited functionalities",
    price: "$250",
    period: "/month",
    color: "#0C359E",
    featureList: [
      "Unlimited Disk Space",
      "Unlimited Support",
      "Data Migration",
    ],
    btnText: "Get Started",
    btnUrl: "/contact",
    popular: false,
  },
  // {
  //   title: `Women's Health Plan`,
  //   subTitle: `Comprehensive women's health services. Receive expert gynecological care, family planning support, and prenatal/postnatal.`,
  //   price: "$169",
  //   period: "/month",
  //   featureList: [
  //     `Women's Health Services`,
  //     "Gynecological Care",
  //     "Fall Prevention Programs",
  //     "Family Planning",
  //     "Prenatal & Postnatal Support",
  //   ],
  //   btnText: "Get Started",
  //   btnUrl: "/contact",
  //   popular: false,
  // },
  // {
  //   title: "Sports & Fitness Plan",
  //   subTitle:
  //     "Optimized for athletes. Access sports injury assessments, physiotherapy, and expert advice for peak performance and recovery.",
  //   price: "$119",
  //   period: "/month",
  //   featureList: [
  //     "Sports Injury Assessments",
  //     "Physiotherapy Sessions",
  //     "Sports Medicine Experts",
  //     "Fitness Support",
  //   ],
  //   btnText: "Get Started",
  //   btnUrl: "/contact",
  //   popular: false,
  // },
];

export default function ContactForm({ defaultValue = 0 }) {
  const [showModal, setShowModal] = useState(false);
  const [btnAgree, setBtnAgree] = useState(false);
  const [firstname, setFirstname] = useState("");
  const [lastName, setLastname] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [address, setAddress] = useState("");
  const [organization, setOrganization] = useState("");
  const [expertiseList, setExpertiseList] = useState([]);
  const [errorFirstname, setErrorFirstname] = useState("");
  const [errorLastname, setErrorLastname] = useState("");
  const [errorPhone, setErrorPhone] = useState("");
  const [errorAddress, setErrorAddress] = useState("");
  const [errorExpert, setErrorExpert] = useState("");
  const [errorTerm, setErrorTerm] = useState("");
  const [error, setError] = useState("");
  const [selectedValue, setSelectedValue] = useState(defaultValue);

  // Update selected value when the defaultValue changes
  useEffect(() => {
    setSelectedValue(defaultValue);
  }, [defaultValue]);

  const handleCheckboxChange = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      // Add the selected expertise to the list
      setExpertiseList([...expertiseList, value]);
    } else {
      // Remove the deselected expertise from the list
      setExpertiseList(expertiseList.filter((item) => item !== value));
    }
  };
  const handleOrganizationChange = (e) => {
    setOrganization(e.target.value); // Update organization state with selected value
    setError("");
  };
  const handleFirstNameChange = (e) => {
    setFirstname(e.target.value); // Update firstName state with the input value
    setErrorFirstname("");
  };
  const handleLastNameChange = (e) => {
    setLastname(e.target.value); // Update firstName state with the input value
    setErrorLastname("");
  };
  const handlePhoneNumberChange = (e) => {
    setPhoneNumber(e.target.value); // Update firstName state with the input value
    setErrorPhone("");
  };
  const handleAddressChange = (e) => {
    setAddress(e.target.value); // Update firstName state with the input value
    setErrorAddress("");
  };
  const handleShowModal = () => {
    setShowModal(true);
    setBtnAgree(false);
  };
  const handleCloseModalWithReject = () => {
    setShowModal(false);
    setBtnAgree(false);
  };
  const handleCloseModalWithAccept = () => {
    setShowModal(false);
    setBtnAgree(true);
    setErrorTerm("");
  };

  const handleRadioChange = (e) => {
    setBtnAgree(e.target.checked); // Update state based on radio button selection
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!organization) {
      setError("Please select an organization. *"); // Set error message if organization is not selected
      return; // Exit function early if no organization is selected
    }
    if (!firstname) {
      setErrorFirstname("Please Input Your FirstName. *");
      return;
    }
    if (!lastName) {
      setErrorLastname("Please Input Your LastName. *");
      return;
    }
    if (!phoneNumber) {
      setErrorPhone("Please Input Your Phone Number. *");
      return;
    }
    if (!address) {
      setErrorAddress("Please Input Your Address *");
      return;
    }
    if (!expertiseList) {
      setErrorExpert("Please Input Your Expert. *");
      return;
    }

    if (btnAgree === false) {
      setErrorTerm("You didn't accept our agreement");
      return;
    }
    // Handle form submission logic here
    console.log("Form submitted");
  };

  console.log("defaultvalue", selectedValue);

  return (
    <div className="cs_contact_form cs_style_1 cs_white_bg cs_radius_30">
      <div className="row">
        <div className="col-lg-6">
          <label className="cs_input_label cs_heading_color">First Name</label>
          <input
            type="text"
            className="cs_form_field"
            placeholder=""
            required
            value={firstname} // Bind the input value to the firstName state
            onChange={handleFirstNameChange}
          />
          {errorFirstname && (
            <p className="error-message" style={{ color: "red" }}>
              {errorFirstname}
            </p>
          )}
          <div className="cs_height_42 cs_height_xl_25" />
        </div>
        <div className="col-lg-6">
          <label className="cs_input_label cs_heading_color">Last Name</label>
          <input
            type="text"
            className="cs_form_field"
            placeholder=""
            required
            value={lastName} // Bind the input value to the firstName state
            onChange={handleLastNameChange}
          />
          {errorLastname && (
            <p className="error-message" style={{ color: "red" }}>
              {errorLastname}
            </p>
          )}
          <div className="cs_height_42 cs_height_xl_25" />
        </div>
        <div className="col-lg-12">
          <label className="cs_input_label cs_heading_color">
            Phone Number
          </label>
          <input
            type="text"
            className="cs_form_field"
            placeholder=""
            required
            value={phoneNumber} // Bind the input value to the firstName state
            onChange={handlePhoneNumberChange}
          />
          {errorPhone && (
            <p className="error-message" style={{ color: "red" }}>
              {errorPhone}
            </p>
          )}
          <div className="cs_height_42 cs_height_xl_25" />
        </div>
        <div className="col-lg-12">
          <label className="cs_input_label cs_heading_color">
            Your Address
          </label>
          <textarea
            className="cs_form_field"
            placeholder=""
            rows={2}
            value={address} // Bind the input value to the firstName state
            onChange={handleAddressChange}
          />
          {errorAddress && (
            <p className="error-message" style={{ color: "red" }}>
              {errorAddress}
            </p>
          )}
          <div className="cs_height_42 cs_height_xl_25" />
        </div>
        {/* ---------- */}

        <div className="col-lg-12">
          <label className="cs_input_label cs_heading_color">
            Select The plan
          </label>
          <select
            className="cs_form_field mb-2"
            value={selectedValue}
            onChange={(e) => setSelectedValue(e.target.value)}
          >
            <option value="0">Cabinet</option>
            <option value="1">Small Clinic</option>
            <option value="2">Medium Clinic</option>
            <option value="3">Health Care Authority</option>
            <option value="4">Hospital</option>
          </select>
          <div className="cs_height_42 cs_height_xl_25" />
        </div>
        {/* ----------- */}
        <div className="col-lg-12">
          <label className="cs_input_label cs_heading_color">
            Organization type
          </label>
          {error && (
            <p className="error-message" style={{ color: "red" }}>
              {error}
            </p>
          )}
          <div className="cs_radio_group">
            <div className="cs_radio_wrap  col-lg-4 col-md-6 col-sm-12">
              <input
                className="cs_radio_input"
                type="radio"
                name="organization"
                id="Small Cabinet"
                defaultValue="Small Cabinet"
                onChange={handleOrganizationChange}
                checked={organization === "Small Cabinet"}
              />
              <label className="cs_radio_label" htmlFor="Small Cabinet">
                Small Cabinet
              </label>
            </div>
            <div className="cs_radio_wrap  col-lg-4 col-md-6 col-sm-12">
              <input
                className="cs_radio_input"
                type="radio"
                name="organization"
                id="Public Clinic"
                defaultValue="Public Clinic"
                onChange={handleOrganizationChange}
                checked={organization === "Public Clinic"}
              />
              <label className="cs_radio_label" htmlFor="Public Clinic">
                Public Clinic
              </label>
            </div>
            <div className="cs_radio_wrap  col-lg-4 col-md-6 col-sm-12">
              <input
                className="cs_radio_input"
                type="radio"
                name="organization"
                id="Private Clinic"
                defaultValue="Private Clinic"
                onChange={handleOrganizationChange}
                checked={organization === "Private Clinic"}
              />
              <label className="cs_radio_label" htmlFor="Private Clinic">
                Private Clinic
              </label>
            </div>
            <div className="cs_radio_wrap col-lg-4 col-md-6 col-sm-12">
              <input
                className="cs_radio_input"
                type="radio"
                name="organization"
                id="Government Healthcare Authority"
                defaultValue="Government Healthcare Authority"
                onChange={handleOrganizationChange}
                checked={organization === "Government Healthcare Authority"}
              />
              <label
                className="cs_radio_label"
                htmlFor="Government Healthcare Authority"
              >
                Government Healthcare Authority
              </label>
            </div>
            <div className="cs_radio_wrap  col-lg-4 col-md-6 col-sm-12">
              <input
                className="cs_radio_input"
                type="radio"
                name="organization"
                id="Private Hospital"
                defaultValue="Private Hospital"
                onChange={handleOrganizationChange}
                checked={organization === "Private Hospital"}
              />
              <label className="cs_radio_label" htmlFor="Private Hospital">
                Private Hospital
              </label>
            </div>
            <div className="cs_radio_wrap  col-lg-4 col-md-6 col-sm-12">
              <input
                className="cs_radio_input"
                type="radio"
                name="organization"
                id="Public Hospital"
                defaultValue="Public Hospital"
                onChange={handleOrganizationChange}
                checked={organization === "Public Hospital"}
              />
              <label className="cs_radio_label" htmlFor="Public Hospital">
                Public Hospital
              </label>
            </div>
          </div>

          <div className="cs_height_42 cs_height_xl_25" />
        </div>
        {/* ==== */}
        <div className="col-lg-12">
          <label className="cs_input_label cs_heading_color">
            Choose your area of expertise
          </label>
          {errorExpert && (
            <p className="error-message" style={{ color: "red" }}>
              {errorExpert}
            </p>
          )}
          <div className="row">
            <div className=" col-lg-4 my-1">
              <input
                className="check-box-style"
                // style={{ width: "20px", height: "20px" }}
                type="checkbox"
                name="expertise"
                id="General Consulting"
                defaultValue="General Consulting"
                onChange={handleCheckboxChange}
                checked={expertiseList.includes("General Consulting")}
              />
              <label className="cs_radio_label" htmlFor="General Consulting">
                General Consulting
              </label>
            </div>
            <div className="col-lg-4">
              <input
                className="check-box-style"
                type="checkbox"
                name="expertise"
                id="Pediatric"
                defaultValue="Pediatric"
                onChange={handleCheckboxChange}
                checked={expertiseList.includes("Pediatric")}
              />
              <label className="cs_radio_label" htmlFor="Pediatric">
                Pediatric
              </label>
            </div>
            <div className="col-lg-4">
              <input
                className="check-box-style"
                type="checkbox"
                name="expertise"
                id="Gyneco/Obstetric"
                defaultValue="Gyneco/Obstetric"
                onChange={handleCheckboxChange}
                checked={expertiseList.includes("Gyneco/Obstetric")}
              />
              <label className="cs_radio_label" htmlFor="Gyneco/Obstetric">
                Gyneco/Obstetric
              </label>
            </div>
            <div className=" col-lg-4 my-1">
              <input
                className="check-box-style"
                type="checkbox"
                name="expertise"
                id="Anesthesia"
                defaultValue="Anesthesia"
                onChange={handleCheckboxChange}
                checked={expertiseList.includes("Anesthesia")}
              />
              <label className="cs_radio_label" htmlFor="Anesthesia">
                Anesthesia
              </label>
            </div>
            <div className=" col-lg-4 my-1">
              <input
                className="check-box-style"
                type="checkbox"
                name="expertise"
                id="Emergency"
                defaultValue="Emergency"
                onChange={handleCheckboxChange}
                checked={expertiseList.includes("Emergency")}
              />
              <label className="cs_radio_label" htmlFor="Emergency">
                Emergency
              </label>
            </div>

            <div className=" col-lg-4 my-1">
              <input
                className="check-box-style"
                type="checkbox"
                name="expertise"
                id="Internal Medicine"
                defaultValue="Internal Medicine"
                onChange={handleCheckboxChange}
                checked={expertiseList.includes("Internal Medicine")}
              />
              <label className="cs_radio_label" htmlFor="Internal Medicine">
                Internal Medicine
              </label>
            </div>

            <div className=" col-lg-4 my-1">
              <input
                className="check-box-style"
                type="checkbox"
                name="expertise"
                id="Psychiatry"
                defaultValue="Psychiatry"
                onChange={handleCheckboxChange}
                checked={expertiseList.includes("Psychiatry")}
              />
              <label className="cs_radio_label" htmlFor="Psychiatry">
                Psychiatry
              </label>
            </div>
            <div className=" col-lg-4 my-1">
              <input
                className="check-box-style"
                type="checkbox"
                name="expertise"
                id="Dermatology"
                defaultValue="Dermatology"
                onChange={handleCheckboxChange}
                checked={expertiseList.includes("Dermatology")}
              />
              <label className="cs_radio_label" htmlFor="Dermatology">
                Dermatology
              </label>
            </div>
            <div className=" col-lg-4 my-1">
              <input
                className="check-box-style"
                type="checkbox"
                name="expertise"
                id="Orthopedics"
                defaultValue="Orthopedics"
                onChange={handleCheckboxChange}
                checked={expertiseList.includes("Orthopedics")}
              />
              <label className="cs_radio_label" htmlFor="Orthopedics">
                Orthopedics
              </label>
            </div>
            <div className=" col-lg-4 my-1">
              <input
                className="check-box-style"
                type="checkbox"
                name="expertise"
                id="Oncology"
                defaultValue="Oncology"
                onChange={handleCheckboxChange}
                checked={expertiseList.includes("Oncology")}
              />
              <label className="cs_radio_label" htmlFor="Oncology">
                Oncology
              </label>
            </div>
            <div className=" col-lg-4 my-1">
              <input
                className="check-box-style"
                type="checkbox"
                name="expertise"
                id="Diabetes"
                defaultValue="Diabetes"
                onChange={handleCheckboxChange}
                checked={expertiseList.includes("Diabetes")}
              />
              <label className="cs_radio_label" htmlFor="Diabetes">
                Diabetes
              </label>
            </div>
          </div>
          <div className="cs_height_42 cs_height_xl_25" />
          <span className="term-condition" onClick={handleShowModal}>
            Terms & Conditions
          </span>
          {errorTerm && (
            <p className="error-message" style={{ color: "red" }}>
              {errorTerm}
            </p>
          )}
          {/* ====== pop up term & condition =============== */}
          <div
            className={`modal ${showModal ? "show" : ""}`}
            onClick={handleCloseModalWithReject}
          >
            <div className="modal-content" onClick={(e) => e.stopPropagation()}>
              <h2>Term & Condition</h2>
              <p className="p-4">
                beatae enim, vero quam. Recusandae quidem laborum distinctio,
                iste dolorum incidunt impedit minus quasi odio placeat ullam a
                Lorem ipsum dolor sit, amet consectetur adipisicing elit. Dicta
                porro veritatis repellendus accusamus sit est asperiores, iure
                quisquam suscipit illo error maiores amet adipisci dolor
                accusantium alias? Doloremque, corporis architecto.
              </p>

              <div
                className="cs_radio_wrap  col-lg-12"
                style={{ marginLeft: "20px" }}
              >
                <input
                  className="cs_radio_input"
                  type="radio"
                  name="terms"
                  id="agree"
                  checked={btnAgree} // Controlled component: value depends on state
                  onChange={handleRadioChange}
                />
                <label className="cs_radio_label" htmlFor="agree">
                  agree with terms & condition
                </label>
              </div>

              <div className="btn_cs d-flex align-items-center justify-content-end ">
                <button
                  className="cs_btn cs_style_2 "
                  style={{ width: "20%" }}
                  onClick={handleCloseModalWithReject}
                >
                  <span>cancel</span>
                </button>
                <button
                  // className={`cs_btn cs_style_1 `}
                  style={{
                    width: "20%",
                    marginLeft: 20,
                    // backgroundColor: btnAgree ? "#007bff" : "#ccc",
                    backgroundImage: btnAgree
                      ? `linear-gradient(270deg, #307bc4 0%, #274760 100%)`
                      : "gray",
                  }}
                  disabled={!btnAgree}
                  onClick={handleCloseModalWithAccept}
                >
                  <span>agree</span>
                </button>
              </div>
            </div>
          </div>
          {/* =============================================== */}
        </div>
        <div className="col-lg-12 d-flex align-items-center justify-content-end">
          <div className="cs_height_18" />
          <button
            className="cs_btn cs_style_1 "
            onClick={handleSubmit}
            // disabled={!organization}
          >
            <span>Submit</span>
            <i>
              <img src="/images/icons/arrow_white.svg" alt="Icon" />
              <img src="/images/icons/arrow_white.svg" alt="Icon" />
            </i>
          </button>
        </div>
      </div>
    </div>
  );
}
