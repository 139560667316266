import React from "react";
import IconBoxStyle11 from "../../IconBox/IconBoxStyle11";
import Spacing from "../../Spacing";

export default function ContactInfoSection({ sectionTitle }) {
  return (
    <div className="container">
      <h2 className="cs_fs_72 mb-0">{sectionTitle}</h2>
      <Spacing md="70" lg="50" />
      <div className="row g-4 g-xl-3 g-xxl-5">
        <div className="col-xl-4">
          <IconBoxStyle11
            title="Phone"
            subTitle="+855 12750 523"
            iconSrc="/images/contact/icon_1.svg"
          />
        </div>
        <div className="col-xl-4">
          <IconBoxStyle11
            title="Email"
            subTitle="info.vibolrith-health-system.com"
            iconSrc="/images/contact/icon_2.svg"
          />
        </div>
        <div
          className="col-xl-4"
          style={{ cursor: "pointer" }}
          onClick={() =>
            window.open("https://maps.app.goo.gl/XqWbq3FFkwrkjHFZ6", "blank")
          }
        >
          <IconBoxStyle11
            title="Location"
            subTitle="35Z, Street 600, Sangkat Beoung Kak2, Khan Tuol Kork, Phnom Penh, Cambodia"
            iconSrc="/images/contact/icon_3.svg"
          />
        </div>
      </div>
      <Spacing md="35" />
      {/* Start Google Map */}
      <div className="cs_map">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3908.680723084815!2d104.8930535675372!3d11.57473058951265!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x310951e5aeab5c97%3A0x7c85b6be3f305d57!2sCAMIS%20Solutions!5e0!3m2!1sen!2skh!4v1715258700744!5m2!1sen!2skh"
          id="map"
          title="Google Map"
        ></iframe>
      </div>
      {/* End Google Map */}
    </div>
  );
}
