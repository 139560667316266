import { Icon } from "@iconify/react";
import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export default function RequestDemo() {
  const [selectedDate, setSelectedDate] = useState(null);
  const [organization, setOrganization] = useState("");
  const [error, setError] = useState("");
  const handleOrganizationChange = (e) => {
    setOrganization(e.target.value); // Update organization state with selected value
    setError("");
  };
  return (
    <>
      <form action="#" className="row">
        <div className="col-lg-6">
          <label className="cs_input_label cs_heading_color">First Name</label>
          <input type="text" className="cs_form_field" placeholder="" />
          <div className="cs_height_42 cs_height_xl_25" />
        </div>
        <div className="col-lg-6">
          <label className="cs_input_label cs_heading_color">Last Name</label>
          <input type="text" className="cs_form_field" placeholder="" />
          <div className="cs_height_42 cs_height_xl_25" />
        </div>
        {/* <div className="col-lg-6">
          <label className="cs_input_label cs_heading_color">
            {" "}
            Name of Your Institution
          </label>
          <input type="text" className="cs_form_field" placeholder="" />
          <div className="cs_height_42 cs_height_xl_25" />
        </div> */}
        <div className="col-lg-12">
          <label className="cs_input_label cs_heading_color">
            Phone Number
          </label>
          <input type="text" className="cs_form_field" placeholder="" />
          <div className="cs_height_42 cs_height_xl_25" />
        </div>
        <div className="col-lg-12">
          <label className="cs_input_label cs_heading_color">
            Your Purpose
          </label>
          <textarea className="cs_form_field" placeholder="" rows={4} />
          <div className="cs_height_42 cs_height_xl_25" />
        </div>

        <div className="col-lg-12">
          <label className="cs_input_label cs_heading_color">
            Name of Your Institution
          </label>
          {error && (
            <p className="error-message" style={{ color: "red" }}>
              {error}
            </p>
          )}
          <div className="cs_radio_group">
            <div className="cs_radio_wrap  col-lg-4 col-md-6 col-sm-12">
              <input
                className="cs_radio_input"
                type="radio"
                name="organization"
                id="Small Cabinet"
                defaultValue="Small Cabinet"
                onChange={handleOrganizationChange}
                checked={organization === "Small Cabinet"}
              />
              <label className="cs_radio_label" htmlFor="Small Cabinet">
                Small Cabinet
              </label>
            </div>
            <div className="cs_radio_wrap  col-lg-4 col-md-6 col-sm-12">
              <input
                className="cs_radio_input"
                type="radio"
                name="organization"
                id="Public Clinic"
                defaultValue="Public Clinic"
                onChange={handleOrganizationChange}
                checked={organization === "Public Clinic"}
              />
              <label className="cs_radio_label" htmlFor="Public Clinic">
                Public Clinic
              </label>
            </div>
            <div className="cs_radio_wrap  col-lg-4 col-md-6 col-sm-12">
              <input
                className="cs_radio_input"
                type="radio"
                name="organization"
                id="Private Clinic"
                defaultValue="Private Clinic"
                onChange={handleOrganizationChange}
                checked={organization === "Private Clinic"}
              />
              <label className="cs_radio_label" htmlFor="Private Clinic">
                Private Clinic
              </label>
            </div>
            <div className="cs_radio_wrap col-lg-4 col-md-6 col-sm-12">
              <input
                className="cs_radio_input"
                type="radio"
                name="organization"
                id="Government Healthcare Authority"
                defaultValue="Government Healthcare Authority"
                onChange={handleOrganizationChange}
                checked={organization === "Government Healthcare Authority"}
              />
              <label
                className="cs_radio_label"
                htmlFor="Government Healthcare Authority"
              >
                Government Healthcare Authority
              </label>
            </div>
            <div className="cs_radio_wrap  col-lg-4 col-md-6 col-sm-12">
              <input
                className="cs_radio_input"
                type="radio"
                name="organization"
                id="Private Hospital"
                defaultValue="Private Hospital"
                onChange={handleOrganizationChange}
                checked={organization === "Private Hospital"}
              />
              <label className="cs_radio_label" htmlFor="Private Hospital">
                Private Hospital
              </label>
            </div>
            <div className="cs_radio_wrap  col-lg-4 col-md-6 col-sm-12">
              <input
                className="cs_radio_input"
                type="radio"
                name="organization"
                id="Public Hospital"
                defaultValue="Public Hospital"
                onChange={handleOrganizationChange}
                checked={organization === "Public Hospital"}
              />
              <label className="cs_radio_label" htmlFor="Public Hospital">
                Public Hospital
              </label>
            </div>
          </div>

          <div className="cs_height_42 cs_height_xl_25" />
        </div>

        <div className="col-lg-12">
          <button className="cs_btn cs_style_1">
            <span>Submit</span>
            <i>
              <img src="/images/icons/arrow_white.svg" alt="Icon" />
              <img src="/images/icons/arrow_white.svg" alt="Icon" />
            </i>
          </button>
        </div>
      </form>
    </>
  );
}
