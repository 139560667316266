import React, { useState } from "react";
import BannerSectionStyle5 from "../Section/BannerSection/BannerSectionStyle5";
import ContactForm from "../ContactForm";
import Section from "../Section";
import ContactInfoSection from "../Section/ContactInfoSection";
import { pageTitle } from "../../helpers/PageTitle";
import QRPage from "../QR";
import { useLocation } from "react-router-dom";

export default function Contact() {
  pageTitle("Contact");
  const [qrData, setQRData] = useState("Hello, QR Code!");
  const { pathname } = useLocation();
  console.log("pathname", pathname);
  const defaultValue = pathname.split("/")[2];
  return (
    <>
      <BannerSectionStyle5
        bgUrl="/images/contact/banner_bg.svg"
        imgUrl="/images/home_1/img_home12.png"
        title="Registration"
        subTitle="Connecting Communities Through Registration"
      />
      <div className="container cs_mt_minus_110">
        <div className="row">
          <div className="col-lg-8 ">
            <ContactForm defaultValue={defaultValue} />
          </div>
          <div className="col-lg-3 offset-lg-1 d-flex align-items-center justify-content-center">
            {/* <QRPage data={qrData} /> */}
            <img
              src="/images/QR/qr-code.jpg"
              alt="qr-code"
              style={{
                width: "100%",
                height: "50%",
                objectFit: "cover", // Crop the image to cover the container
                objectPosition: "center", // Center the cropped portion of the image
              }}
            />
          </div>
        </div>
      </div>
      <Section
        topMd={200}
        topLg={150}
        topXl={100}
        bottomMd={200}
        bottomLg={150}
        bottomXl={110}
      >
        <ContactInfoSection sectionTitle="Find Us Here" />
      </Section>
    </>
  );
}
