import React from "react";

export default function Feature({ iconSrc, title, subTitle }) {
  console.log("title", title);
  console.log("subTitle", subTitle);
  return (
    <div className="cs_feature cs_style_1 cs_shadow_1 cs_radius_25 cs_white_bg">
      <h2 className="cs_feature_title cs_semibold cs_fs_40 cs_center">
        <span className="cs_feature_icon cs_accent_bg cs_center rounded-circle">
          <img src={iconSrc} alt="Icon" />
        </span>
        <span className="cs_hero_subtitle cs_fs_24">{title}</span>
      </h2>
      {/* <p className="m-0 text-center">{subTitle}</p> */}
      <div>
        <div
          className="cs_random_features_col"
          style={{
            display: "flex",
            alignItems: "center",
            padding: 0,
            margin: 0,
          }}
        >
          <ul style={{ listStyleType: "disc" }}>
            {subTitle?.map((item, index) => (
              <li key={index}>{item?.sub}</li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}
