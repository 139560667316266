import React from "react";
import BannerSectionStyle3 from "../Section/BannerSection/BannerSectionStyle3";
import { pageTitle } from "../../helpers/PageTitle";
import Section from "../Section";
import PricingSection from "../Section/PricingSection";
import FaqSectionStyle4 from "../Section/FaqSection/FaqSectionStyle4";
import BannerSectionStyle10 from "../Section/BannerSection/BannerSectionStyle10";
const pricingData = [
  {
    title: "Cabinet / កាបីណេ",
    subTitle:
      "Special small family cabinet. Benefit from tailored system settings and support for your golden years.",
    price: "$25",
    period: "/month",
    color: "#7BC9FF",
    featureList: [
      "No Installation Fee",
      "Single Database",
      "Single Domain",
      "Limited Support",
      "Data Migration",
    ],
    btnText: "Get Started",
    btnUrl: "/contact/0",
    popular: false,
  },
  {
    title: "Small Clinic / ក្លីនិចលក្ខណះគ្រួសារ",
    subTitle:
      "Extensive, medium-sized clinic tailored to your needs with a few employees. Experience unlimited functionalities.",
    price: "$50",
    period: "/month",
    color: "#387ADF",
    featureList: [
      "No Installation Fee",
      "Single Database",
      "Single Domain",
      "Unlimited Support",
      "Data Migration",
    ],
    btnText: "Get Started",
    btnUrl: "/contact/1",
    popular: false,
  },
  {
    title: "Medium Clinic /  ក្លីនិចមធ្យម",
    subTitle:
      "Extensive, for your medium clinic with medium employees. Experience unlimited functionalities",
    price: "$75",
    period: "/month",
    color: "#1D24CA",
    featureList: [
      "No Installation Fee",
      "Single Database",
      "Single Domain",
      "Unlimited Support",
      "Data Migration",
    ],
    btnText: "Get Started",
    btnUrl: "/contact/2",
    popular: false,
  },
  {
    title: "Health Care Authority / មណ្ឌលសុខភាព",
    subTitle:
      "Optimized for government healthcare Institutions and NGOs. Get access to administration and assessment",
    price: "$15",
    period: "/month",
    color: "#0C359E",
    featureList: [
      "No Installation Fee",
      "Unlimited Disk Space",
      "Unlimited Support",
      "Data Migration",
    ],
    btnText: "Get Started",
    btnUrl: "/contact/3",
    popular: false,
  },
  {
    title: "Hospital / មន្ទីរពេទ្យ",
    subTitle:
      "Extensive, for your Hospital with many employees. Experience unlimited functionalities",
    price: "$250",
    period: "/month",
    color: "#0C359E",
    featureList: [
      "No Installation Fee",
      "Unlimited Disk Space",
      "Unlimited Support",
      "Data Migration",
    ],
    btnText: "Get Started",
    btnUrl: "/contact/4",
    popular: false,
  },
];
// const faqData = [
//   {
//     title: "What services does Vibolrith’s Health System offer?",
//     content:
//       "At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesent voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui.",
//   },
//   {
//     title: "How do I schedule an appointment with Vibolrith’s Health System?",
//     content:
//       "At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesent voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui.",
//   },
//   {
//     title: "Do you accept insurance?",
//     content:
//       "At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesent voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui.",
//   },
//   {
//     title: "What should I bring to my appointment?",
//     content:
//       "At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesent voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui.",
//   },
//   {
//     title: "How do I request a prescription refill?",
//     content:
//       "At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesent voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui.",
//   },
// ];
export default function PricingPlan() {
  pageTitle("Pricing Plan");
  return (
    <>
      <BannerSectionStyle3
        bgUrl="/images/pricing_plan/banner_bg.svg"
        imgUrl="/images/home_1/img_home7.png"
        title="Find the Right Plan <br>to You"
        subTitle="Explore our membership options and begin your journey to better health"
        btnText="Get Starter"
        btnUrl="/contact/1"
      />
      <Section
        topMd={10}
        topLg={10}
        topXl={50}
        bottomMd={200}
        bottomLg={150}
        bottomXl={110}
      >
        <PricingSection
          sectionTitle="Choose Your Vibolrith’s Health System  <br />Membership Plan"
          data={pricingData}
          is_small={false}
        />
      </Section>
      <Section
        topMd={200}
        topLg={150}
        topXl={110}
        className="cs_footer_margin_0"
      >
        <BannerSectionStyle10
          imgUrl="/images/home_1/img_home2.png"
          title="Choose Your Plan and Invest in Your Health Today!"
        />
      </Section>
    </>
  );
}
