import React, { useState } from "react";
import Pricing from "../../Pricing";
import SectionHeading from "../../SectionHeading";
import Spacing from "../../Spacing";

export default function PricingSection({
  sectionTitle,
  data,
  is_small = false,
}) {
  const [checkIndex, setCheckIndex] = useState(0);

  return (
    <div className="container">
      <SectionHeading title={sectionTitle} center />
      <Spacing md="72" lg="50" />
      <div className="row cs_gap_y_50 d-flex justify-content-center ">
        {data?.map((item, index) => (
          <div
            className={
              is_small
                ? "col-xl-4 col-md-12 col-sm-12"
                : "col-xl-4 col-md-6 col-sm-9 col-xs-12"
            }
            key={index}
          >
            <Pricing
              {...item}
              is_small={is_small}
              setCheckIndex={setCheckIndex}
              index={index}
              checkIndex={checkIndex}
            />
          </div>
        ))}
      </div>
    </div>
  );
}
