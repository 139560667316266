import React from "react";

import RequestDemo from ".";
import SectionHeading from "../SectionHeading";
import Spacing from "../Spacing";
import BannerSectionStyle3 from "../Section/BannerSection/BannerSectionStyle3";
import Section from "../Section";
import RequestDemoForm from "./RequestDemoForm";

export default function RequestDemoSection2({
  sectionTitle,
  sectionTitleUp,
  imgUrl,
}) {
  return (
    <>
      <BannerSectionStyle3
        bgUrl="/images/appointments/banner_bg.svg"
        imgUrl="/images/home_1/img_home12.png"
        title="We are always available to answer your questions, suggestions and wishes"
        subTitle="Our team of experts will be happy to advise you on how to use our system or switch from another system to our system."
      />
      <Section
        topMd={10}
        topLg={10}
        topXl={50}
        bottomMd={200}
        bottomLg={150}
        bottomXl={110}
      >
        <RequestDemoForm />
      </Section>
    </>
  );
}
