import React from "react";
import "../../sass/component/Privacy.scss";
import Section from "../Section";
import FaqSection from "../Section/FaqSection";
const faqData = [
  {
    title: "Language preference",
    content:
      "This cookie is first party and placed on your device by our web site. \n \n When you initially visit our web site you will receive a notification offering you a language preference, should the home page of our site be different to your browser’s language settings. \n \n When you select your language preference, we will remember your choice by using a cookie so that the next time you visit the page you will not need to select your language, unless you delete the cookie.  If you do not delete the cookie it will remain on your browser for up to 6 months.",
  },
  {
    title: "MaxMind GeoIP2 Precision Country",
    content:
      "MaxMind offers geographic location service called GeoIP2 Precision Country, which uses IP addresses to determine location information.  We use this service to determine which continent and country a web site visitor is from so we can process that information for our cookie notification feature.MaxMind’s service sets a third party cookie which is used to identify individual browsers behind a shared IP address and apply security settings on a per-browser basis.  It does not correspond to any user ID on our web site, and does not store any personally identifiable information.This cookie will remain on your browser for up to 12 months, or, until you delete cookies..",
  },
  {
    title: "Addtoany sharing tool",
    content:
      "AddToAny is a universal sharing tool for websites and apps allowing us to show sharing buttons on our website so visitors can easily share our content via social sharing platforms like Facebook, LinkedIn and messenger services such as Skype or Whatsapp. These cookies are third party and placed on your device by Addtoany. Addtoany does not track your web site usage, however, when you share a page on our site it reports these events to our Google Analytics account and it interacts with the sharing tool (such as Facebook, Twitter, Skype, Whatsapp, etc.) chosen by you.  Each sharing tool regulates information independently from us and we encourage you to consult the respective privacy policies of each service.You can delete Addtoany cookies via your browser settings, but doing so will prevent it from working correctly.You can view the Addtoany privacy policy here: https://www.addtoany.com/privacy",
  },
  {
    title: "Google Analytics",
    content:
      "Google Analytics is a web analytics tool that helps us understand how visitors engage with our website. With Google Analytics we can view a variety of reports about how visitors interact with our website so that we can improve the content, the delivery of information and our products and services. These cookies are first party and placed on your device by Google Analytics.These cookies assist in collecting information about your device, operating system, browser type, anonymized IP, page visits on our web site, referring web sites, the time you visited the site, whether you visited before and other technical data. Google Analytics collects information anonymously. It reports website trends without identifying individual visitors.  You can opt out of Google Analytics without affecting how you visit our site – for more information on opting out of being tracked by Google Analytics across all websites you use, visit this Google opt-out page.You can acquire further information about Google Analytics here: https://policies.google.com/privacy",
  },
];

export default function Cookie() {
  return (
    <>
      <div className="container">
        <div className="container-privacy">
          <div>
            <h2 className="cs_fs_40 ol-title">What are cookies?</h2>
            <span className="container-title">
              Cookies are short strings that are saved, while browsing the
              website, on your computer, smartphone or tablet and allow our
              website to recognize you after the first visit and then improve
              your user experience.
              <br />
              <br />
            </span>
          </div>
          <div>
            <h2 className="cs_fs_40 ol-title">How do we use cookies?</h2>
            <h5>The skinny</h5>
            <span className="container-title">
              Our web site uses cookies to: <br />
              <br />
              <ul className="ul-styled">
                <li>
                  Remember if you have agreed to our use of cookies on this site
                </li>
                <li>Remember your language preferences</li>
                <li>
                  Allow our third party sharing tool to work and interact with
                  our analytics tool and social media platforms.
                </li>
                <li>
                  Allow us to collect statistics on site usage via a third party
                  analytics tool.
                </li>
              </ul>
              <br />
              <br />
              Enabling these cookies is not strictly necessary for the website
              to work but it will provide you with a better browsing experience.
              You can delete or block these cookies, but if you do that some
              features of this site may not work as intended.
              <br />
              <br />
              The{" "}
              <span style={{ color: "#428aca" }}>
                cookie-related information is NOT used to identify you
                personally
              </span>{" "}
              and the pattern data is fully under our control. These cookies are
              not used for any purpose other than those described here. <br />
              <br />
            </span>
          </div>
          <div>
            <h2 className="cs_fs_40 ol-title">The details</h2>
            <span className="container-title">
              We have provided more details below on how cookies are used on our
              web site, whether they are placed by us or third parties: Consent
              cookie
            </span>
            <FaqSection data={faqData} sectionTitle="" sectionTitleUp="" />
            <br />
            <br />
          </div>
          <div>
            <h2 className="cs_fs_40 ol-title">How to control cookies</h2>
            <span className="container-title">
              You can control and/or delete cookies as you wish – for details,
              see aboutcookies.org. You can delete all cookies that are already
              on your computer and you can set most browsers to prevent them
              from being placed. If you do this, however, you may have to
              manually adjust some preferences every time you visit a site and
              some services and functionalities may not work.
              <br />
              <br />
              You would need to set your cookie preferences for each device and
              each browser used for navigating the Internet.
            </span>
          </div>
        </div>
      </div>
    </>
  );
}
