import React from "react";
import Section from "../Section";
import AwardSection from "../Section/AwardSection";

const awardData = [
  {
    title: "Efficient Patient Management",
    subTitle:
      "Clinic management systems streamline patient registration, appointment scheduling, and record-keeping processes, reducing administrative burdens and improving workflow efficiency.",
    iconUrl: "images/icons/award.svg",
  },
  {
    title: "Enhanced Patient Experience",
    subTitle:
      "By offering online appointment booking, reminders, and secure patient portals, clinic management systems empower patients to take a more active role in their healthcare, leading to higher levels of patient satisfaction and engagement.",
    iconUrl: "images/icons/award.svg",
  },
  {
    title: "Improved Clinical Workflow",
    subTitle:
      "Electronic health records (EHR) and clinical decision support tools within clinic management systems enable healthcare providers to access patient information quickly, make informed treatment decisions, and deliver high-quality care more efficiently.",
    iconUrl: "images/icons/award.svg",
  },
  {
    title: "Optimized Resource Utilization",
    subTitle:
      "Clinic management systems help healthcare organizations optimize resource allocation by providing insights into appointment utilization, staff productivity, and facility usage, leading to better resource management and cost savings.",
    iconUrl: "images/icons/award.svg",
  },
  {
    title: "Streamlined Billing and Revenue Cycle Management",
    subTitle:
      "Automated billing and invoicing features in clinic management systems streamline the billing process, reduce billing errors, and accelerate revenue cycle management, ultimately improving financial performance",
    iconUrl: "images/icons/award.svg",
  },
  {
    title: "Compliance and Data Security",
    subTitle:
      "Clinic management systems are designed to comply with healthcare regulations, such as HIPAA, ensuring that patient data is protected, and privacy is maintained. Robust security measures safeguard against data breaches and unauthorized access.",
    iconUrl: "images/icons/award.svg",
  },
  {
    title: "Integration and Interoperability",
    subTitle:
      "Many clinic management systems offer integration with external systems, such as laboratory information systems, diagnostic imaging systems, and health information exchanges, facilitating seamless data exchange and interoperability across healthcare settings",
    iconUrl: "images/icons/award.svg",
  },
  {
    title: "Data-driven Decision Making",
    subTitle:
      "Clinic management systems provide valuable insights and analytics through reporting tools, enabling healthcare organizations to make data-driven decisions, identify trends, and monitor key performance indicators to continuously improve operations and patient outcomes.      ",
    iconUrl: "images/icons/award.svg",
  },
  {
    title: "Scalability and Flexibility",
    subTitle:
      "Clinic management systems are scalable and adaptable to the needs of healthcare organizations of all sizes, from small clinics to large healthcare systems. They can accommodate growth, changes in workflow, and evolving regulatory requirements.",
    iconUrl: "images/icons/award.svg",
  },
  {
    title: "Remote Access and Telehealth Support",
    subTitle:
      "With the rise of telehealth, many clinic management systems offer remote access and telehealth support features, allowing healthcare providers to conduct virtual consultations, provide remote care, and engage with patients outside of traditional clinic settings.",
    iconUrl: "images/icons/award.svg",
  },
];

export default function ChooseUsPage() {
  return (
    <>
      <Section topMd={0} topLg={0} topXl={10}>
        <AwardSection
          sectionTitle="Vibolrith’s Health System is Intuitive and efficiently to use"
          data={awardData}
        />
      </Section>
    </>
  );
}
